import styled from 'styled-components'

import { ModalSubtitle, ModalWrapper } from '../../Modal/messageModal'

import { Button as SuperButton, InputElement as IE, Span as SuperSpan } from '../../superSetCommons'

interface IInput {
	error: string | boolean
	maxLength: string
}

interface IForm {
	htmlFor?: string
}

export const Wrapper = styled(ModalWrapper)``

export const Title = styled(ModalSubtitle)`
	margin: 0 auto;
	text-align: left;
	line-height: 2;
`

export const TextMessage = styled(ModalSubtitle)`
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
	line-height: 2;
`

export const ReturnButton = styled(SuperButton)`
	margin: 20px 0;
	border-radius: 2px;
	${props =>
		props.disabled
			? `
	border: 1px solid ${props.theme.colors.button_disabled_border};
	background: ${props.theme.colors.secundary_hover};
	`
			: `
	border: 1px solid ${props.theme.colors.red_alert_hover};
	background: transparent linear-gradient(180deg, ${props.theme.colors.red_alert_hover} 0%,
	${props.theme.colors.red_alert} 100%) 0% 0% no-repeat padding-box;
	`};

	&:hover {
		background: ${props => (props.disabled ? props.theme.colors.secundary : props.theme.colors.red_alert_hover)};
	}
`

export const FormLabel = styled(SuperSpan)<IForm>`
	width: 100%;
	display: block;
	margin-top: 12px;
	text-align: left;
	letter-spacing: 0px;
	color: ${props => props.theme.colors.font_dark};
`

export const InputElement = styled(IE)`
	width: 100%;
	min-width: 130px;
	height: 48px;
	margin-top: 1%;
	border-radius: initial;
`

export const Logo = styled.img`
	display: block;
	width: 350px;
	padding: 20px 0 20px 0;
	margin: 0 auto;
	max-width: 350px;
`

export const CloseIcon = styled.img`
	width: 15px;
	height: 15px;
	display: block;
	cursor: pointer;
	float: right;
`

export const SpanError = styled(SuperSpan)`
	display: block;
	letter-spacing: 0px;
	padding-top: 5px;
	text-align: left;
	color: ${props => props.theme.colors.red_alert};
`
