import moment from 'moment'

export const formatDate = (value: string) => {
	try {
		let d = value.replace(/\D/g, '')
		const f = d.substring(0, 2)
		const s = d.substring(2, 4)
		const t = d.substring(4, 8)

		d = f
		d += s ? `/${s}` : ''
		d += t ? `/${t}` : ''

		return d
	} catch {
		return value
	}
}

export const formatCPF = (value: string) => {
	try {
		let c = value.replace(/\D/g, '')
		const f = c.substring(0, 3)
		const s = c.substring(3, 6)
		const t = c.substring(6, 9)
		const l = c.substring(9, 11)

		c = f
		c += s ? `.${s}` : ''
		c += t ? `.${t}` : ''
		c += l ? `-${l}` : ''

		return c
	} catch {
		return value
	}
}
export const formatCep = (value: string) => {
	let c = value.replace(/\D/g, '')
	const f = c.substring(0, 5)
	const s = c.substring(5, 8)

	c = f
	c += s ? `-${s}` : ''

	return c
}

export const formatPhone = (value: string) => {
	try {
		let p = value.replace(/\D/g, '')
		const f = p.substring(0, 2)
		const s = p.substring(2, 7)
		const t = p.substring(7, 11)

		p = f ? `(${f}` : ''
		p += s ? `) ${s}` : ''
		p += t ? ` ${t}` : ''

		return p
	} catch {
		return value
	}
}

export const validateEmail = (value: string) => {
	try {
		const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return !value.match(regex)
	} catch {
		return false
	}
}

export const getAge = (birthDay: string) => {
	try {
		const dateToFormat = `${birthDay.substring(8, 10)}/${birthDay.substring(
			5,
			7
		)}/${birthDay.substring(0, 4)}`
		const str = moment(dateToFormat, 'DD/MM/YYYY').fromNow()
		const age = parseInt(str.split(' ')[0])
		return age
	} catch {
		return ''
	}
}

export const maskCard = (value: string) => {
	try {
		let formatted = value.replace(/\D+/g, '')
		formatted = formatted.replace(/\d{4}(?=.)/g, '$& ')
		return formatted
	} catch {
		return value
	}
}

export const unMaskCard = (masked: string) => {
	try {
		const unmasked = masked.replace(/ /g, '')
		return unmasked
	} catch {
		return masked
	}
}

export const formatMoney = (value: number, locale?: string, currency?: string) => {
	try {
		value.toLocaleString(locale || 'pt-br', { style: 'currency', currency: currency || 'BRL' })
	} catch {
		return value
	}
}

export const formatCEP = (str: string) => {
	try {
		const value = str.substr(0, 9)
		const re = /^([\d]{5})-*([\d]{3})/
		return value.replace(re, '$1-$2')
	} catch {
		return str
	}
}
