import base64 from 'base-64'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import utf8 from 'utf8'

import { Loader } from '@cuidardigital/commons/components/Loader'
import isValidCPF from '@cuidardigital/commons/utils/cpf'
import { formatCPF } from '@cuidardigital/commons/utils/masks'
import { login, reset } from '../../services/login'
import MessageModal from '../Modal'
import Reset from './Reset'

import {
	ComponentWrapper,
	ConsentText,
	ContainerCheckbox,
	Form,
	FormFieldWrapper,
	FormInput,
	FormLabel,
	FormWrapper,
	InputError,
	Logo,
	ResetButton,
	Span,
	SubmitButtonRed
} from './styles'

import logo from '../../assets/img/logo.png'

interface IModalObj {
	title?: string
	message: string
	buttonText?: string
}

interface IModalState {
	open?: boolean
	reset?: boolean
}

interface IFormState {
	password?: string
	login?: string
	optins?: boolean | any[]
}

const encodeBase64 = (str: string) => {
	const bytes = utf8.encode(str)
	return base64.encode(bytes)
}

const formInitialState = {
	password: '',
	login: ''
	// optins: false
}

const PageLogin = () => {
	const errorsInitialState: any = {
		...Object.keys(formInitialState).reduce((value, item) => ({ ...value, [item]: false }), {})
	}
	const [formValues, setFormValues] = useState<IFormState>(formInitialState)
	const [errors, setErrors] = useState(errorsInitialState)
	const [formIsWrong, setFormIsWrong] = useState(true)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalObj>({ message: '' })
	const [showModal, setShowModal] = useState<IModalState>({ open: false, reset: true })

	useEffect(() => {
		setTimeout(() => setLoading(false), 500)
	}, [])

	useEffect(() => {
		normalizerErrors()
	}, [errors])

	const handleConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		setFormValues({ ...formValues, optins: checked })
		setErrors({ ...errors, consent: !checked })
	}

	const handleLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { login } = formValues
		if (login !== value) {
			const newCpf = formatCPF(value)
			setFormValues({ ...formValues, login: newCpf })
			setErrors({
				...errors,
				login: !isValidCPF(
					value
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				)
			})
		}
	}

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && !formIsWrong) handleSubmit()
	}

	const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { password } = formValues
		if (password !== value) {
			setFormValues({ ...formValues, password: value })
			setErrors({ ...errors, password: value.length < 8 })
		}
	}

	const handleSubmit = async () => {
		setLoading(true)
		try {
			login(formValues)
				.then(response => {
					if (response.status === 200) {
						const params = {
							accessToken: response.data.accessToken,
							user: response.data.user
						}
						navigate(`/?param=${encodeBase64(JSON.stringify(params))}`)
					} else {
						setMessageToShow({
							message: response.data.message,
							onClose: setShowMessage
						})
						setShowMessage(true)
					}
				})
				.catch(e => {
					setMessageToShow({
						message: JSON.stringify(e),
						onClose: setShowMessage
					})
					setShowMessage(true)
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (error) {
			setLoading(false)
			setMessageToShow({
				message: JSON.stringify(error),
				onClose: setShowMessage
			})
			setShowMessage(true)
		}
	}

	const handleSubmitReset = useCallback(cpf => {
		setLoading(true)
		reset(cpf)
			.then(response => {
				if (response.status < 207) {
					setShowModal({ open: true, reset: false })
				} else {
					setShowModal({ open: false })
					setMessageToShow({
						message: response.data.message,
						onClose: setShowMessage
					})
					setShowMessage(true)
				}
			})
			.catch(e => {
				setMessageToShow({
					message: JSON.stringify(e),
					onClose: setShowMessage
				})
				setShowMessage(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	const handleOpenReset = () => setShowModal({ open: true, reset: true })

	const handleCloseModal = () => setShowModal({ open: false, reset: false })

	const normalizerErrors = () => {
		const wrong = Object.values(errors).some(b => b) || Object.values(formValues).some(v => !v)
		setFormIsWrong(wrong)
	}

	if (error !== '') {
		setMessageToShow({ message: error, onClose: setShowMessage })
		setShowMessage(true)
		setError('')
	}

	if (loading) {
		return <Loader />
	}

	return (
		<>
			{/* <MessageModal
				buttonText='Ok, entendi'
				// tslint:disable-next-line: jsx-no-lambda
				setShowModal={() => {}}
				title='Pedimos desculpas'
				messageHTML={`<span style='font-size: 20px; line-height: 1.5; margin-top: 16px;'>
						<br />Olá. O Saúde Digital está com instabilidades técnicas e servidores fora do ar. <br />
						<br />Nossa equipe está somando forças para resolver o problema.
						Pedimos desculpas e
						reforçamos o nosso compromisso em cuidar da saúde de seus pacientes.
						<br /><br /><br />
					</span>`}
				withButton={false}
			/> */}
			{loading && <Loader />}
			{showMessage && <MessageModal maxWidth='450px' {...messageToShow} />}
			{showModal.open && (
				<Reset
					setShowModal={handleCloseModal}
					sendModal={handleSubmitReset}
					reset={showModal.reset}
				/>
			)}
			<ComponentWrapper>
				<FormWrapper>
					<Form>
						<Logo src={logo} />
						<FormFieldWrapper>
							<FormLabel fontSize='14px/24px'>Login</FormLabel>
							<FormInput
								type='text'
								id='login'
								name='login'
								// error={errors.login}
								value={formValues.login}
								onChange={handleLogin}
								placeholder='Digite aqui o seu login'
								onKeyPress={handleKeyPress}
							/>
							{/* {errors.login && <InputError fontSize='12px/18px'>Conteúdo inválido. Tente novamente.</InputError>} */}
						</FormFieldWrapper>
						<FormFieldWrapper>
							<FormLabel fontSize='14px/24px'>Senha</FormLabel>
							<FormInput
								type='password'
								id='password'
								name='password'
								// error={errors.password}
								value={formValues.password}
								onChange={handlePassword}
								placeholder='Digite aqui a sua senha'
								halfField
								onKeyPress={handleKeyPress}
							/>
							{/* {errors.password && <InputError fontSize='12px/18px'>Conteúdo inválido. Tente novamente.</InputError>} */}
						</FormFieldWrapper>
						<SubmitButtonRed id='button-pageLogin-access' onClick={handleSubmit}>
							Acessar
						</SubmitButtonRed>
						<ResetButton id='button-pageLogin-password' onClick={handleOpenReset}>
							Esqueceu a senha?
						</ResetButton>
					</Form>
				</FormWrapper>
			</ComponentWrapper>
		</>
	)
}

export default PageLogin
