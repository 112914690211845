import React, { useState } from 'react'

import iconX from '@cuidardigital/commons/assets/img/close.svg'
import ShowModal from '@cuidardigital/commons/components/Modal'
import isValidCPF from '@cuidardigital/commons/utils/cpf'
import { formatCPF } from '@cuidardigital/commons/utils/masks'
import ReCAPTCHA from 'react-google-recaptcha'

import {
	CloseIcon,
	FormLabel,
	InputElement,
	Logo,
	ReturnButton,
	SpanError,
	TextMessage,
	Title,
	Wrapper
} from './styles'

import logo from '../../../assets/img/logo.png'

const ERROR_TYPE = {
	INCOMPLETE: '1',
	INVALID: '2'
}

interface IProps {
	setShowModal: (param: boolean) => void
	sendModal: (param: string) => void
	reset: boolean
}

const Reset: React.FC<IProps> = ({ setShowModal, sendModal, reset }) => {
	const [cpf, setCpf] = useState('')
	const [cpfError, setCpfError] = useState('false')
	const [recapValid, setRecapValid] = useState(false)

	const recaptchaRef = React.createRef()

	const handleCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const newCpf = formatCPF(value)
		const cpfIsWrong =
			(newCpf.length < 14 && ERROR_TYPE.INCOMPLETE) ||
			(!isValidCPF(
				newCpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')
			) &&
				ERROR_TYPE.INVALID) ||
			''
		setCpf(newCpf)
		setCpfError(cpfIsWrong)
	}

	const onSubmit = () => {
		const recaptchaValue = recaptchaRef.current.getValue()
		console.log('recaptchaValue', recaptchaValue)
		sendModal(cpf)
	}

	const closeModal = () => {
		setShowModal(false)
	}

	const onChange = value => {
		setRecapValid(!!value)
	}

	return (
		<ShowModal zIndex='11' maxWidth='450px' onClose={closeModal}>
			<Wrapper>
				{reset && (
					<a onClick={closeModal}>
						<CloseIcon src={iconX} />
					</a>
				)}

				<div>
					<Logo src={logo} />
				</div>

				{reset ? (
					<div>
						<Title wrap>
							<strong>Olá!</strong>
							<br />
							Por favor, digite seu CPF. Você receberá um e-mail para alterar sua senha
						</Title>

						<div>
							<FormLabel fontSize='14px/24px' htmlFor='cpf'>
								CPF
							</FormLabel>
							<InputElement
								p='16px'
								withBorder
								id='cpf'
								value={cpf}
								error={cpfError !== 'false' && cpfError}
								placeholder='Digite seu CPF'
								onChange={handleCpf}
								// @ts-ignore
								maxLength='14'
							/>
							{cpfError && (
								<SpanError fontSize='12px/18px'>
									{cpfError !== 'false' &&
										(cpfError === ERROR_TYPE.INCOMPLETE
											? 'Por favor, preencha seu CPF'
											: 'CPF incorreto! Por favor, preencha novamente')}
								</SpanError>
							)}
						</div>

						<ReturnButton onClick={onSubmit} disabled={!!cpfError || !recapValid}>
							Enviar
						</ReturnButton>

						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
							onChange={onChange}
						/>
					</div>
				) : (
					<div>
						<TextMessage>
							<strong>Pronto!</strong>
							<br />É só acessar o seu e-mail cadastrado e redefinir
							<br /> a senha
						</TextMessage>

						<ReturnButton onClick={closeModal}>Ok, entendi</ReturnButton>
					</div>
				)}
			</Wrapper>
		</ShowModal>
	)
}

export default Reset
