import api from '@cuidardigital/commons/services/api'
import { endpointsCreator } from '@cuidardigital/commons/services/endpoints'
import CryptoJS from 'crypto-js';

const cryptoKey = process.env.GATSBY_ENCRYPT_KEY

interface ILogin {
	[key: string]: any
}

const login = async (values: ILogin) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.USER.LOGIN, {
			login: CryptoJS.AES.encrypt(values.login.replace(/[^\d]/g, ''), cryptoKey).toString(),
			password: CryptoJS.AES.encrypt(values.password, cryptoKey).toString(),
		})

		return response
	} catch (err) {
		return err.response
	}
}

const reset = async (cpf: string) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.USER.RESET_PASSWORD, {
			username: cpf.replace(/[^\d]/g, '')
		})

		return response
	} catch (err) {
		return err.response
	}
}

export { login, reset }
